import React, { useState } from 'react';
import Modal from 'cccisd-modal';
import { DeploymentPlayer } from 'cccisd-laravel-assignment';
import style from './style.css';
import PropTypes from 'prop-types';
import { client as apollo } from 'cccisd-apollo';
import evalQuery from './deploymentQuery.graphql';
import assignmentQuery from './assignmentQuery.graphql';

const EditSurvey = props => {
    const { onComplete, pawn, progress } = props;
    const modal = React.createRef();

    const [currentPawn, setCurrentPawn] = useState(pawn);
    const [assignmentId, setAssignmentId] = useState(null);
    const [deploymentId, setDeploymentId] = useState(null);

    const surveyList =
        progress.role === 'coach'
            ? ['survey12', 'survey32', 'survey34', 'survey24']
            : ['survey36', 'survey37', 'survey38', 'survey39'];

    const getAssignmentId = async () => {
        const deploymentHandle = progress.role === 'coach' ? 'feedbackSurveyCoach' : 'feedbackSurveyTeacher';

        const response = await apollo.query({
            query: evalQuery,
            fetchPolicy: 'network-only',
            variables: {
                deploymentHandle,
            },
        });

        const queriedId = response.data.flows.deployment.assignment.assignmentId;

        await getLastDeployment(queriedId);
    };

    const getLastDeployment = async queriedId => {
        const response = await apollo.query({
            query: assignmentQuery,
            fetchPolicy: 'network-only',
            variables: {
                pawnId: +pawn.pawnId,
                assignmentId: queriedId,
            },
        });

        const responseSet = response.data.roles.anyRole.assignmentProgressList[0].deployment.deploymentId;

        setCurrentPawn(pawn);
        setAssignmentId(queriedId);
        setDeploymentId(responseSet);
    };

    return (
        <div className={style.modalBox}>
            <Modal
                trigger={
                    <button type="button" className={`btn btn-default ${style.buttonStyle}`} disabled={props.disabled}>
                        Edit Current Feedback Survey
                    </button>
                }
                beforeShow={getAssignmentId}
                size="large"
                title="Edit Feedback Survey"
                ref={modal}
            >
                <div className={style.fixContainer}>
                    <DeploymentPlayer
                        deploymentId={deploymentId}
                        pawnId={+currentPawn.pawnId}
                        pawnHash={currentPawn.pawnHash}
                        onComplete={() => {
                            onComplete(+currentPawn.pawnId, currentPawn.pawnHash, +deploymentId, +assignmentId);
                            modal.current.close();
                        }}
                        assignmentOptions={surveyList}
                        flowProps={{ hidePlayButton: true }}
                    />
                </div>
            </Modal>
        </div>
    );
};

EditSurvey.propTypes = {
    onComplete: PropTypes.func,
    pawn: PropTypes.object,
    progress: PropTypes.object,
    disabled: PropTypes.bool,
};

export default EditSurvey;
